var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "event-popup-wrap" }, [
    _c("div", { staticClass: "event-popup-item" }, [
      _c("div", { staticClass: "ep-contents" }, [
        _c("div", { staticClass: "event-2023" }, [
          _c("img", {
            staticStyle: { cursor: "pointer" },
            attrs: {
              src: _vm.popup.popupImageUrl,
              width: "650",
              height: "360",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.linkDetail($event)
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "btn-wrap" }, [
        _c(
          "button",
          {
            staticClass: "btn-5-close ep-close",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.close(true)
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.dispDontShowDay) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "btn-once-close ep-close",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.close(false)
              },
            },
          },
          [_vm._v(" 닫기 ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }