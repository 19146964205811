var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _c("div", { staticClass: "inner-top" }, [
        _c(
          "article",
          { staticClass: "medical-trend-area" },
          [
            _c("h2", { staticClass: "hidden" }, [_vm._v(" 메디컬 트렌드 ")]),
            _c(
              "swiper",
              {
                staticClass: "swiper-container medicalBanner",
                attrs: { options: _vm.medicalSwiperOptions },
              },
              [
                _vm._l(_vm.medicals, function (data) {
                  return _c("swiper-slide", { key: data.id }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "app-medical-trend-main",
                              query: { id: data.id, entry: 147 },
                            })
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: data.filePath,
                            width: "572",
                            height: "392",
                            loading: "lazy",
                          },
                        }),
                      ]
                    ),
                  ])
                }),
                _c("div", {
                  staticClass: "swiper-pagination",
                  attrs: { slot: "pagination" },
                  slot: "pagination",
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c("div", { staticClass: "items-wrap" }, [
          _c("article", { staticClass: "web-symposium-area" }, [
            _c("div", { staticClass: "bg-box" }, [
              _c("h2", [_vm._v("진행예정 세미나")]),
              _c("p", { staticClass: "num-txt" }, [
                _vm._v(" " + _vm._s(_vm.seminarCount)),
                _c("span", [_vm._v("건")]),
              ]),
            ]),
            _c("div", { staticClass: "ws-item-box" }, [
              _c("dl", [
                _c("dt", [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.linkToSeminar(_vm.seminar.id)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.seminar !== null ? _vm.seminar.title : "")
                      ),
                    ]
                  ),
                ]),
                _c("dd", [
                  _vm.seminar
                    ? _c("ul", [
                        _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.seminar.startDate) +
                              " ~ " +
                              _vm._s(
                                _vm.seminar.endDate !== null
                                  ? _vm.seminar.endDate
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.seminar.lecturerName.split(",")[0]) +
                              _vm._s(
                                _vm.seminar.lecturerName.split(",").length > 1
                                  ? " 외 " +
                                      (_vm.seminar.lecturerName.split(",")
                                        .length -
                                        1) +
                                      "명"
                                  : ""
                              )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn-more",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "app-webseminar-client" })
                    },
                  },
                },
                [_vm._v(" 더보기 ")]
              ),
            ]),
          ]),
          _c("article", { staticClass: "ongoing-survey-area" }, [
            _c("div", { staticClass: "bg-box" }, [
              _c("h2", [_vm._v("참여 가능한 설문 조사")]),
              _c("p", { staticClass: "num-txt" }, [
                _vm._v(" " + _vm._s(_vm.surveyCount)),
                _c("span", [_vm._v("건")]),
              ]),
            ]),
            _c("div", { staticClass: "ws-item-box" }, [
              _c("dl", [
                _vm.surveyCount > 0
                  ? _c("dt", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.linkToSurvey(
                                _vm.survey.id,
                                _vm.survey.surveyType
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.survey !== null ? _vm.survey.title : "")
                          ),
                        ]
                      ),
                    ])
                  : _c("dt", [
                      _c("a", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.survey !== null ? _vm.survey.title : ""
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                _c("dd", [
                  _c("ul", [
                    _vm.survey !== null && _vm.survey.rewardPoint > 0
                      ? _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.survey.summary) +
                              " / " +
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  _vm.survey.rewardPoint
                                )
                              ) +
                              " 포인트 지급 "
                          ),
                        ])
                      : _c("li", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.survey !== null ? _vm.survey.summary : ""
                              ) +
                              " "
                          ),
                        ]),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.survey !== null && _vm.survey.id > 0,
                            expression: "survey !== null && survey.id > 0",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.survey !== null ? _vm.survey.startDate : ""
                            ) +
                            " ~ " +
                            _vm._s(
                              _vm.survey !== null ? _vm.survey.endDate : ""
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn-more",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "app-survey-main" })
                    },
                  },
                },
                [_vm._v(" 더보기 ")]
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "inner-bottom" },
        [
          _c(
            "article",
            { staticClass: "drug-information-area" },
            [
              _c("h2", { staticClass: "hidden" }, [_vm._v(" 약품 정보 ")]),
              _c(
                "swiper",
                {
                  ref: "swiper",
                  staticClass: "swiper-container infoBanner",
                  attrs: { options: _vm.drugInfoSwiperOptions },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.clickSwiper($event)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "swiper-scrollbar" }),
                  _c("div", {
                    staticClass: "swiper-button-prev",
                    attrs: { slot: "button-prev" },
                    slot: "button-prev",
                  }),
                  _c("div", {
                    staticClass: "swiper-button-next",
                    attrs: { slot: "button-next" },
                    slot: "button-next",
                  }),
                  _vm._l(_vm.drugInfos, function (data) {
                    return _c("swiper-slide", { key: data.id }, [
                      _c("a", [
                        _c("img", {
                          attrs: {
                            src: data.filePath,
                            width: "1304",
                            height: "262",
                            loading: "lazy",
                          },
                        }),
                      ]),
                    ])
                  }),
                  _c("div", {
                    staticClass: "swiper-pagination",
                    attrs: { slot: "pagination" },
                    slot: "pagination",
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
        ],
        1
      ),
      _vm._l(_vm.popups, function (popup) {
        return _c("popup-main", {
          key: popup.id,
          attrs: { popup: popup },
          on: { link: _vm.linkPopupPage, close: _vm.closePopup },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }