<template>
  <main>
    <div class="inner-top">
      <article class="medical-trend-area">
        <h2 class="hidden">
          메디컬 트렌드
        </h2>
        <swiper
          class="swiper-container medicalBanner"
          :options="medicalSwiperOptions"
        >
          <!-- slides -->
          <swiper-slide
            v-for="data in medicals"
            :key="data.id"
          >
            <a @click="$router.push({ name: 'app-medical-trend-main', query: { id: data.id, entry: 147 } })">
              <img
                :src="data.filePath"
                width="572"
                height="392"
                loading="lazy"
              >
            </a>
          </swiper-slide>
          <div
            slot="pagination"
            class="swiper-pagination"
          />
        </swiper>
      </article>

      <div class="items-wrap">
        <article class="web-symposium-area">
          <div class="bg-box">
            <h2>진행예정 세미나</h2>
            <p class="num-txt">
              {{ seminarCount }}<span>건</span>
            </p>
          </div>

          <div class="ws-item-box">
            <dl>
              <dt><a @click="linkToSeminar(seminar.id)">{{ seminar !== null ? seminar.title : '' }}</a></dt>
              <dd>
                <ul v-if="seminar">
                  <li>
                    {{ seminar.startDate }} ~ {{ seminar.endDate !== null ? seminar.endDate : '' }}
                  </li>
                  <li>{{ seminar.lecturerName.split(',')[0] }}{{ seminar.lecturerName.split(',').length > 1 ? ` 외 ${seminar.lecturerName.split(',').length - 1}명` : '' }}</li>
                </ul>
              </dd>
            </dl>
            <button
              class="btn-more"
              @click="$router.push({ name: 'app-webseminar-client' })"
            >
              더보기
            </button>
          </div>
        </article>

        <article class="ongoing-survey-area">
          <div class="bg-box">
            <h2>참여 가능한 설문 조사</h2>
            <p class="num-txt">
              {{ surveyCount }}<span>건</span>
            </p>
          </div>

          <div class="ws-item-box">
            <dl>
              <dt v-if="surveyCount > 0">
                <a @click="linkToSurvey(survey.id, survey.surveyType)">{{ survey !== null ? survey.title : '' }}</a>
              </dt>
              <dt v-else>
                <a>
                  {{ survey !== null ? survey.title : '' }}
                </a>
              </dt>
              <dd>
                <ul>
                  <li v-if="survey !== null && survey.rewardPoint > 0">
                    {{ survey.summary }} / {{ survey.rewardPoint | $numberFormatter }} 포인트 지급
                  </li>
                  <li v-else>
                    {{ survey !== null ? survey.summary : '' }}
                  </li>
                  <li v-show="survey !== null && survey.id > 0">
                    {{ survey !== null ? survey.startDate : '' }} ~ {{ survey !== null ? survey.endDate : '' }}
                  </li>
                </ul>
              </dd>
            </dl>
            <button
              class="btn-more"
              @click="$router.push({ name: 'app-survey-main' })"
            >
              더보기
            </button>
          </div>
        </article>
      </div>
    </div>

    <div class="inner-bottom">
      <article class="drug-information-area">
        <h2 class="hidden">
          약품 정보
        </h2>
        <swiper
          ref="swiper"
          class="swiper-container infoBanner"
          :options="drugInfoSwiperOptions"
          @click.native="clickSwiper"
        >
          <div class="swiper-scrollbar" />
          <div
            slot="button-prev"
            class="swiper-button-prev"
          />
          <div
            slot="button-next"
            class="swiper-button-next"
          />

          <swiper-slide
            v-for="data in drugInfos"
            :key="data.id"
          >
            <a>
              <img
                :src="data.filePath"
                width="1304"
                height="262"
                loading="lazy"
              >
            </a>
          </swiper-slide>
          <div
            slot="pagination"
            class="swiper-pagination"
          />
        </swiper>
      </article>
      <confirm-dialog :options="confirmProps" />
    </div>
    <!-- 이벤트&공지 팝업 -->
    <popup-main
      v-for="popup in popups"
      :key="popup.id"
      :popup="popup"
      @link="linkPopupPage"
      @close="closePopup"
    />
  </main>
</template>

<script>
import axios from '@axios'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
// import sampleMedicalImg from '@/assets/images/banner/img-mainbanner.jpg'
// import sampleDrugInfoImg from '@/assets/images/banner/img-infobanner.jpg'
import { mapGetters } from 'vuex'
import { callExternalApi } from '@/core/global/global-liveinfo'
import { setStatistics } from '@/common/statistics/service'
import PopupMain from '@/views/apps/popup/PopupMain.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    PopupMain,
  },
  data() {
    return {
      medicals: [
        { id: 1, filePath: null },
        { id: 2, filePath: null },
        { id: 3, filePath: null },
        { id: 4, filePath: null },
      ],
      medicalSwiperOptions: {
        slidesPerView: 1,
        centeredSlides: false,
        slideToClickedSlide: false,
        // grabCursor: false,
        initialSlide: 0,
        paginationClickable: false,
        scrollbar: ".swiper-scrollbar",
        touchRatio: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      drugInfos: [
        { id: 1, filePath: null },
        { id: 2, filePath: null },
        { id: 3, filePath: null },
        { id: 4, filePath: null },
      ],
      drugInfoSwiperOptions: {
        slidesPerView: 1,
        centeredSlides: false,
        slideToClickedSlide: false,
        // grabCursor: false,
        initialSlide: 0,
        paginationClickable: false,
        scrollbar: ".swiper-scrollbar",
        touchRatio: 0,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "fraction",
        },
      },
      surveyCount: 0,
      survey: {
        id: 0,
        surveyType: null,
        title: null,
        summary: null,
        startDate: null,
        endDate: null,
        rewardPoint: 0,
      },
      seminarCount: 0,
      seminar: null,
      popups: [],
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
      infoSetting: 'infoData/getInfoSetting',
      medicalDept: 'infoData/getMedicalDept',
      shaYoyangNum: 'infoData/getShaYoyangNum',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    }),
  },
  watch: {
    infoSetting() {
      this.getSurvey()
      this.getNoticePopup()
    },
  },
  mounted() {
    this.getMedicalTrend()
    this.getWebSeminar()
    this.getDrugInfo()

    if (this.infoSetting) {
      this.getSurvey()
      this.getNoticePopup()
    }
  },
  methods: {
    linkToSeminar(id) {
      this.$router.push({ name: 'app-webseminar-client', params: { id, seminarType: 1, entry: 111 } })
    },
    linkToSurvey(id, surveyType) {
      this.$router.push({ name: 'app-survey-main', params: { id, surveyType, entry: 156 } })
    },
    getMedicalTrend() {
      axios.get("/fu/dashboard/medicals", {
      })
        .then(rs => {
          this.medicals = rs.data
        })
        .catch(error => {
          this.showAlertDialog(error.response.data)
        })
    },
    getDrugInfo() {
      axios.get("/fu/dashboard/druginfos", {
        params: {
          medicalDept: this.medicalDept,
        }
      })
        .then(rs => {
          this.drugInfos = rs.data
        })
        .catch(error => {
          this.showAlertDialog(error.response.data)
        })
    },
    getWebSeminar() {
      axios.get("/fu/dashboard/seminars", {
      })
        .then(rs => {
          this.seminarCount = rs.data.seminarCount
          this.seminar = rs.data.seminar
        })
        .catch(error => {
          this.showAlertDialog(error.response.data)
        })
    },
    getSurvey() {
      axios.post('/fu/dashboard/surveys', {
        medicalDept: this.medicalDept,
        shaYoyangNum: this.shaYoyangNum,
        shaEnLicenseNum: this.shaEnLicenseNum,
      })
        .then(rs => {
          this.surveyCount = rs.data.joinAbleCount
          this.survey = rs.data.survey

          if (rs.data.inCompleteSurvey) {
            const liveInfoId = rs.data.inCompleteSurvey
            const surveyStatus = rs.data.inCompleteSurveyStatus

            let message = ''
            if (surveyStatus === 1) message = '완료하지 않은 설문이 있습니다.\n계속 참여하시겠습니까?'
            else return

            this.showConfirmDialog(message, confirm => {
              if (confirm) {
                this.linkToSurvey(liveInfoId, 1)
              } else {
                axios.patch(`/fu/survey/skip-continue/${liveInfoId}`, this.shaEnLicenseNum)
              }
            })
          }
        })
        .catch(error => {
          this.showAlertDialog(error.response.data)
        })
    },
    getNoticePopup() {
      axios.post('/fu/dashboard/notice-popups', {
        shaEnLicenseNum: this.shaEnLicenseNum,
      })
        .then(rs => {
          this.popups = rs.data
        })
        .catch(error => {
          this.showAlertDialog(error.response.data)
        })
    },
    dontShowNoticePopup(id, againDay) {
      axios.patch(`/fu/dashboard/notice-popup/${id}`, {
        shaEnLicenseNum: this.shaEnLicenseNum,
        popupAgainDays: againDay,
      })
        .then(() => {
        })
        .catch(() => {
          this.showAlertDialog("팝업 다시 보지 않기 설정 과정에서 오류가 발생하였습니다.")
        })
    },
    clickSwiper(event) {
      if (!event.target.classList.contains('swiper-button-next') && !event.target.classList.contains('swiper-button-prev')) {
        const item = this.drugInfos[this.$refs.swiper.$swiper.realIndex]

        if (item.externalLink !== null && item.externalLink.length > 0) {
          setStatistics(136, item.id)

          callExternalApi("LinkUrl", item.externalLink)
        } else this.$router.push({ name: 'app-druginfo-main', query: { id: item.id, entry: 136 } })
      }
    },
    linkPopupPage(obj) {
      // noticeType 1: 공지사항 / 2: 이벤트
      if (obj.noticeType === 1) this.$router.push({ name: 'app-notice-main', query: { id: obj.id, entry: 210 } })
      else if (obj.noticeType === 2) this.$router.push({ name: 'app-event-main', query: { id: obj.id, entry: 124 } })
    },
    closePopup(obj) {
      const targetIdx = this.popups.findIndex(x => x.id === obj.id)
      // 화면에서 팝업 제거(닫기)
      this.popups.splice(targetIdx, 1)

      if (obj.againDay !== -1) this.dontShowNoticePopup(obj.id, obj.againDay)
    },
  },
}
</script>
