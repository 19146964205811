<template>
  <div class="event-popup-wrap">
    <div class="event-popup-item">
      <div class="ep-contents">
        <div class="event-2023">
          <img
            :src="popup.popupImageUrl"
            width="650"
            height="360"
            style="cursor: pointer;"
            @click.stop="linkDetail"
          >
        </div>
      </div>

      <div class="btn-wrap">
        <button
          class="btn-5-close ep-close"
          @click.stop="close(true)"
        >
          {{ dispDontShowDay }}
        </button>
        <button
          class="btn-once-close ep-close"
          @click.stop="close(false)"
        >
          닫기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dispDontShowDay() {
      return this.popup.popupAgainDays ? `${this.popup.popupAgainDays}일동안 보지 않기` : '다시 보지 않기'
    },
  },
  methods: {
    linkDetail() {
      this.$emit('link', {
        id: this.popup.id,
        noticeType: this.popup.noticeType, // 1: 공지사항 / 2: 이벤트
      })
    },
    close(again) {
      this.$emit('close', {
        id: this.popup.id,
        againDay: again ? this.popup.popupAgainDays : -1
      })
    },
  },
}
</script>
